import React from "react";

import keskin from "../images/keskin.png";

import { NavLink } from "react-router-dom";

import Hamburger from "./ui/Hamburger";

const Header = () => {
  return (
    <div className="border-b border-brand-color">
      <div className="container flex items-center justify-between h-auto p-4 mx-auto">
        <div className="relative md:hidden">
          <Hamburger />
        </div>
        <div className="flex items-center md:gap-x-10">
          <NavLink to="/">
            <img
              className="hidden object-cover w-16 h-16 md:block md:w-28 md:h-28"
              src={keskin}
              alt="logo"
            />
          </NavLink>

          <NavLink
            to="/"
            className="text-lg font-extrabold tracking-wide md:text-4xl "
          >
            <h1>KESKİN DOJO</h1>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Header;
