import React from "react";
import logo from "../../images/keskin.png"

const HeaderLogo = () => {
  return (
    <div className="container flex items-center justify-center h-[120px] mx-auto text-white gap-x-4">
      <img
        className="object-cover h-16 lg:h-28"
        src={logo}
        alt="iko"
      />
      <div className="flex flex-col">
        <div className="text-lg font-semibold lg:text-2xl">極真会館空手</div>
        <div className="text-sm font-semibold">Keskin Dojo</div>
      </div>
    </div>
  );
};

export default HeaderLogo;
