import React from "react";
import KataVideo from "../components/KataVideo";

import { useSelector } from "react-redux";
import "../firebase";

const Kata = () => {


  const { katalar } = useSelector((state) => state.katalar);
  



  return (
    <div className="container flex flex-col mx-4 gap-y-4 md:gap-y-8">
      <h1 className="pt-4 pl-4 text-2xl font-bold text-write">Kata</h1>
      
          {katalar.map((kata) => (<KataVideo kata={kata} />))}
        
    </div>
  );
};

export default Kata;
