import React, { useState } from "react";
import { addFaliyet } from "../../../firebase";

export default function FaliyetForm() {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [place, setPlace] = useState("");
  const [time, setTime] = useState("");

  const submitHandle = async (e) => {
    e.preventDefault();

    await addFaliyet({
      name,
      type,
      place,
      time,
    });

    setName("");
    setType("");
    setPlace("");
    setTime("");
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center w-2/5 gap-4 p-10 my-8 bg-gray-200 rounded-md">
        <h2 className="text-2xl font-semibold">Faliyet Ekle</h2>

        <input
          type="text"
          placeholder="Faliyet Adı"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />

        <input
          type="text"
          placeholder="Faliyet Türü"
          value={type}
          onChange={(e) => setType(e.target.value)}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />

        <div className="w-full p-3 bg-gray-300 rounded-md">
          <div>Faliyet Tarihi</div>
          <input
            type="date"
            placeholder="Kayıt Tarihi"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
          />
        </div>

        <input
          type="text"
          placeholder="Faliyet Yeri"
          value={place}
          onChange={(e) => setPlace(e.target.value)}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />

        <div>
          <button
            onClick={submitHandle}
            disabled={name === ""}
            className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
          >
            Ekle
          </button>
        </div>
      </div>
    </div>
  );
}
