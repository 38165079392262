import React from "react";
import { useSelector } from "react-redux";
import "../firebase";
import Belts from "./Belts";

const BeltCard = ({ belt }) => {
  const { sporcular } = useSelector((state) => state.sporcular);

  return (
    <section className="container w-full p-5 mx-auto mb-3 border shadow-xl md:rounded gap-y-2 md:w-auto">
      <Belts belt={belt} />
      {sporcular.map(
        (sporcu) =>
          sporcu.dan === belt && (
            <p className="font-semibold text-brand-color text-md">
              <div>{sporcu.nameSurname}</div>
            </p>
          )
      )}
    </section>
  );
};

export default BeltCard;
