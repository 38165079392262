import React from "react";

const Standing = ({ person } ) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between px-8">
        <div className= "text-lg font-semibold text-brand-color">
          <div>{person.nameSurname}</div>
          <div className="text-write">{person.dan}</div>
        </div>
        <div className="text-xl font-semibold text-red-500">{person.puan}</div>
      </div>
      <hr className="h-[1px] bg-brand-color my-8" />
    </div>
  );
};

export default Standing;
