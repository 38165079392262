import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { emailVerification } from "../../firebase";

import UpdateProfile from "../../components/admin/UpdateProfile";
import UpdatePassword from "../../components/admin/UpdatePassword";
import { MdMail } from "react-icons/md";

const Profile = () => {
  const handleVerification = async () => {
    await emailVerification();
  };

  const { user } = useSelector((state) => state.auth);
  // const handleLogout = async () => {
  //   await signout();
  //   dispatch(logoutHandle());
  //   navigate("/login", {
  //     replace: true,
  //   });
  // };

  if (user) {
    return (
      <div className="container mx-auto">
        <div className="flex justify-between pt-4">
          <h1 className="flex text-xl font-bold text-black">
            {user.photoURL && (
              <img alt="user" className="w-8 h-8 rounded-full" src={user.photoURL} />
            )}
            Hoşgeldin, {user.displayName}
          </h1>
          <span className="flex items-center font-semibold gap-x-2">
            Oturumun Açık ({user.email})
            {!user.emailVerified && (
              <button
                onClick={handleVerification}
                className="flex items-center justify-between p-2 ml-2 text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-600 hover:text-gray-600 gap-x-1"
              >
                <MdMail />
                E-mail Onayla
              </button>
            )}
          </span>
        </div>
        <div className="flex justify-center gap-4 m-5">
          <UpdateProfile />
          <UpdatePassword />
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <button className="p-1 mt-10 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600">
        <Link to="/login">Giriş Yap</Link>
      </button>
    </div>
  );
};

export default Profile;
