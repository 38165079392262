import React from "react";

import Map from "../components/Map";

import ContactInfo from "../components/ContactInfo";

export default function Contact() {
  return (
    <div className="container flex flex-col gap-y-8">
      <ContactInfo />
      <hr className="h-[2px] bg-brand-color md:mx-32 mx-8" />
      <Map />
    </div>
  );
}
