import React, { useEffect, useState } from "react";
import categoryData from "../api/categories.json";
import Category from "./ui/Category";
import Title from "./ui/Title";


const Categories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setCategories(categoryData);
  }, []);
  return (
    <div className="py-2 md:py-4">
      <div className="container mx-auto">
        <Title childeren="Kategoriler" />
        <div className="grid grid-cols-3 xl:grid-cols-9 lg:grid-cols-8 md:grid-cols-6 ">
          {categories &&
            categories.map((category, index) => (
              <Category key={index} category={category} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Categories;
