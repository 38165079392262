import React from "react";

const SenseiPhotoArticle = ({ text, img }) => {
  return (
    <div className="flex flex-col items-center justify-between p-3 sm:rounded-lg gap-x-8 gap-y-4 lg:flex-row bg-hakem-bgc">
      <img className="object-cover border-2 rounded-lg h-96" src={img} alt="sensei"/>
      <div className="text-lg font-medium">{text}</div>
    </div>
  );
};

export default SenseiPhotoArticle;
