import React from "react";
import { useState } from "react";
import { resetPassword  } from "../../firebase";

export default function UpdatePassword() {
    const [password,setPassword] = useState("")
    

    const handleResetSubmit = async e => {
        e.preventDefault()
        const result = await resetPassword(password)
        if (result) {
            setPassword("")
        }
        
      }
  return (
    <div>
      <form onSubmit={handleResetSubmit}>
        <div className="w-[400px] bg-gray-200 p-8 rounded-md grid gap-y-4 py-4  ">
          <h1 className="mb-2 text-xl font-bold">Parolayı Güncelle</h1>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Parola
            </label>
            <div className="mt-1">
              <input
                type="password"
                placeholder="*********"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
              />
            </div>
          </div>
          <button
            disabled={!password}
            type="submit"
            className="flex items-center w-full h-12 px-4 text-sm font-semibold text-orange-500 transition-colors bg-gray-500 rounded-md hover:bg-orange-500 hover:text-gray-700 "
          >
            <span className="mx-auto">Parola Değiştir</span>
          </button>
        </div>
      </form>
    </div>
  );
}
