import React, { useState } from "react";
import { updateFaliyet } from "../../../firebase";

export default function EditFaliyetModal({ close, data }) {
  const [name, setName] = useState(data.name);
  const [type, setType] = useState(data.type);
  const [place, setPlace] = useState(data.place);
  const [time, setTime] = useState(data.time);
  const clickHandle = async () => {
    await updateFaliyet(data.id, {
      name,
      type,
      place,
      time,
    });
    close();
  };
  return (
    <div className="flex flex-col gap-y-2">
      <input
        type="text"
        placeholder="Faliyet Adı"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
      />

      <input
        type="text"
        placeholder="Faliyet Türü"
        value={type}
        onChange={(e) => setType(e.target.value)}
        className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
      />

      <div className="w-full p-3 bg-gray-300 rounded-md">
        <div>Faliyet Tarihi</div>
        <input
          type="date"
          placeholder="Kayıt Tarihi"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />
      </div>

      <input
        type="text"
        placeholder="Faliyet Yeri"
        value={place}
        onChange={(e) => setPlace(e.target.value)}
        className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
      />

      <button
        onClick={clickHandle}
        className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
      >
        Kaydet
      </button>
    </div>
  );
}
