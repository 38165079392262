import React from "react"
import First from "./ui/belts/First"
import Second from "./ui/belts/Second"
import Third from "./ui/belts/Third"
import Fourth from "./ui/belts/Fourth"
import Fifth from "./ui/belts/Fifth"
import Sixth from "./ui/belts/Sixth"
import Seventh from "./ui/belts/Seventh"
import Eighth from "./ui/belts/Eighth"
import Ninth from "./ui/belts/Ninth"
import Tenth from "./ui/belts/Tenth"
import KyuOne from "./ui/belts/KyuOne"
import KyuTwo from "./ui/belts/KyuTwo"
import KyuThree from "./ui/belts/KyuThree"
import KyuFour from "./ui/belts/KyuFour"
import KyuFive from "./ui/belts/KyuFive"
import KyuSix from "./ui/belts/KyuSix"
import KyuSeven from "./ui/belts/KyuSeven"
import KyuEight from "./ui/belts/KyuEight"
import KyuNine from "./ui/belts/KyuNine"
import KyuTen from "./ui/belts/KyuTen"




export let beltNames = [
    "10.Dan",
    "9.Dan",
    "8.Dan",
    "7.Dan",
    "6.Dan",
    "5.Dan",
    "4.Dan",
    "3.Dan",
    "2.Dan",
    "1.Dan",
    "1.Kyu",
    "2.Kyu",
    "3.Kyu",
    "4.Kyu",
    "5.Kyu",
    "6.Kyu",
    "7.Kyu",
    "8.Kyu",
    "9.Kyu",
    "10.Kyu",
]





const Belts = ({belt}) => {

 



    const belts = {
        "10.Kyu": <KyuTen />,
        "9.Kyu": <KyuNine />,
        "8.Kyu": <KyuEight />,
        "7.Kyu": <KyuSeven />,
        "6.Kyu": <KyuSix />,
        "5.Kyu": <KyuFive />,
        "4.Kyu": <KyuFour />,
        "3.Kyu": <KyuThree />,
        "2.Kyu": <KyuTwo />,
        "1.Kyu": <KyuOne />,
        "1.Dan": <First />,
        "2.Dan": <Second />,
        "3.Dan": <Third />,
        "4.Dan": <Fourth />,
        "5.Dan": <Fifth />,
        "6.Dan": <Sixth />,
        "7.Dan": <Seventh />,
        "8.Dan": <Eighth />,
        "9.Dan": <Ninth />,
        "10.Dan": <Tenth />,
    }

    return(
        <div>
            {belts[belt]}
        </div>
        
    )
    
}





export default Belts 
