import React, { useEffect, useState } from "react";
import categoryData from "../api/sideCategories.json";
import Side from "./ui/Side";
import Title from "./ui/Title";

const Sidebar = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setCategories(categoryData);
  }, []);

  return (
    <div className="hidden mr-4 md:block">
      <div className="flex flex-col h-full mt-4 border border-t-0 border-b-0 border-l-0 whitespace-nowrap border-r-brand-color">
        <div className="mx-auto">
          <Title childeren="Kategoriler" />
        </div>
        {categories &&
          categories.map((category, index) => (
            <Side key={index} category={category} />
          ))}
      </div>
    </div>
  );
};

export default Sidebar;
