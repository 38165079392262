import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { MdKeyboardArrowDown } from "react-icons/md";

const ExamKyu = ({ exam }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <section className="pb-6">
      <div className="gap-2 md:gap-4">
        <button
          onClick={toggleCollapse}
          className="flex items-center text-xl font-bold text-brand-color group hover:text-title"
        >
          {exam.name}

          <button className="grid w-6 h-6 rounded-lg place-items-center">
            <span
              className={`transition-all transform ${
                isOpen ? "-rotate-180" : ""
              }`}
            >
              <MdKeyboardArrowDown
                className="group-hover:text-title"
                size={24}
              />
            </span>
          </button>
        </button>

        <Collapse isOpened={isOpen}>
          <object
            className="hidden md:block"
            aria-labelledby="pdf"
            width="100%"
            height="400"
            data={exam.url}
            // type="application/pdf"
          ></object>
          <button className="block p-2 px-3 my-2 text-lg font-semibold rounded-lg bg-title md:hidden hover:shadow-lg">
            <a href={exam.url} target="_blank" rel="noopener noreferrer" >
                Sınavı İndir
            </a>
          </button>
        </Collapse>
      </div>
    </section>
  );
};

export default ExamKyu;
