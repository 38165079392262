import React from "react";

const KyuNine = () => {
  return (
    <div className="flex justify-between px-3 bg-orange-500 rounded w-60">
      <div></div>
      <div className="text-lg font-bold text-brand-color">9.Kyu</div>
      <div className="flex gap-x-1">
        <div className="bg-blue-600 w-[4px] h-full"></div>
      </div>
    </div>
  );
};

export default KyuNine;
