import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth";
import sporcular from "./sporcular";
import katalar from "./katalar"
import haberler from "./haberler";
import faliyetler from "./faliyetler";
import exams from "./exams";
import sensei from "./sensei";
import photos from "./photos";
import modal from "./modal";

const store = configureStore({
  reducer: {
    auth,
    sporcular,
    katalar,
    haberler,
    faliyetler,
    exams,
    sensei,
    photos,
    modal,
  },
});

export default store;
