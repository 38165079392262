import React from "react";

const First = () => {
  return (
    <div className="flex justify-between px-3 bg-black rounded w-60">
      <div></div>
      <div className="text-lg font-bold text-write">
        1.Dan
        </div>
      <div className="flex gap-x-1">
        <div className="bg-gold w-[4px] h-full"></div>
      </div>
    </div>
  );
};

export default First;
