import React from "react";

const Card = ({image}) => {
    return(
        <div className="w-full h-[350px] rounded-lg bg-gray-100">
            <img className="w-full h-[350px] rounded-lg bg-gray-100" src={image} alt="resim"/>
        </div>
    );
};

export default Card;