import React from "react";
import { Route, Routes as Rotalar } from "react-router-dom";

import Home from "./pages/admin/Home";
import Login from "./pages/admin/Login";
import Profile from "./pages/admin/Profile";
import Settings from "./pages/admin/settings/Settings";
import Sporcular from "./pages/admin/settings/Sporcular";
import AdminSensei from "./pages/admin/settings/Sensei";
import Photos from "./pages/admin/settings/Photos";
import Katalar from "./pages/admin/settings/Katalar";
import Haberler from "./pages/admin/settings/Haberler";
import Faliyetler from "./pages/admin/settings/Faliyetler";
import AdminExams from "./pages/admin/settings/Exams";
import AdminHeader from "./components/admin/Header";
import AdminFooter from "./components/admin/Footer";

import Main from "./pages/Main";
import Senpai from "./pages/Senpai";
import Sensei from "./pages/Sensei";
import NationalAthletes from "./pages/NationalAthletes";
import Kata from "./pages/Kata";
import Standings from "./pages/Standings";
import DojoKun from "./pages/DojoKun";
import Exams from "./pages/Exams";
import Activities from "./pages/Activities";
import BeltList from "./pages/BeltList";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import History from "./pages/History";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Footer from "./components/Footer";

export default function Routes() {
  return (
    <div>
      <Rotalar>
        <Route
          exact
          path="/"
          element={
            <div>
              <Header />
              <Main />
              <Footer />
            </div>
          }
        />
        <Route
          exact
          path="/main"
          element={
            <div>
              <Header />
              <Main />
              <Footer />
            </div>
          }
        />
        <Route
          path="/sensei"
          element={
            <div className="flex flex-col justify-between h-screen">
              <Header />
              <div className="container flex justify-between mx-auto mb-auto max-w-8xl">
                <Sidebar />
                <Sensei />
              </div>
              <Footer />
            </div>
          }
        />
        <Route
          path="/senpai"
          element={
            <div className="flex flex-col justify-between h-screen">
              <Header />
              <div className="container flex justify-between mx-auto mb-auto">
                <Sidebar />
                <Senpai />
              </div>
              <Footer />
            </div>
          }
        />
        <Route
          path="/nationalathletes"
          element={
            <div className="flex flex-col justify-between h-screen">
              <Header />
              <div className="container flex justify-between mx-auto mb-auto">
                <Sidebar />
                <NationalAthletes />
              </div>
              <Footer />
            </div>
          }
        />
        <Route
          path="/kata"
          element={
            <div className="flex flex-col justify-between h-screen">
              <Header />
              <div className="container flex justify-between mx-auto mb-auto">
                <Sidebar />
                <Kata />
              </div>
              <Footer />
            </div>
          }
        />
        <Route
          path="/standings"
          element={
            <div className="flex flex-col justify-between h-screen">
              <Header />
              <div className="container flex justify-between mx-auto mb-auto">
                <Sidebar />
                <Standings />
              </div>
              <Footer />
            </div>
          }
        />
        <Route
          path="/dojokun"
          element={
            <div className="flex flex-col justify-between h-screen">
              <Header />
              <div className="container flex justify-start mx-auto mb-auto">
                <Sidebar />
                <DojoKun />
              </div>
              <Footer />
            </div>
          }
        />
        <Route
          path="/exams"
          element={
            <div className="flex flex-col justify-between h-screen">
              <Header />
              <div className="container flex justify-start mx-auto mb-auto">
                <Sidebar />
                <Exams />
              </div>
              <Footer />
            </div>
          }
        />
        <Route
          path="/activities"
          element={
            <div className="flex flex-col justify-between h-screen">
              <Header />
              <div className="container flex justify-between mx-auto mb-auto">
                <Sidebar />
                <Activities />
              </div>
              <Footer />
            </div>
          }
        />
        <Route
          path="/belts"
          element={
            <div className="flex flex-col justify-between h-screen">
              <Header />
              <div className="container flex justify-between mx-auto mb-auto">
                <Sidebar />
                <BeltList />
              </div>
              <Footer />
            </div>
          }
        />
        <Route
          path="/about"
          element={
            <div className="flex flex-col justify-between h-screen">
              <Header />

              <AboutUs />

              <Footer />
            </div>
          }
        />
        <Route
          path="/contact"
          element={
            <div className="flex flex-col justify-between h-screen">
              <Header />
              <div className="container flex mx-auto mb-auto">
                <Sidebar />
                <Contact />
              </div>
              <Footer />
            </div>
          }
        />
        <Route
          path="/history"
          element={
            <div className="flex flex-col justify-between h-screen">
              <Header />
              <div className="container flex justify-between mx-auto mb-auto">
                <Sidebar />
                <History />
              </div>
              <Footer />
            </div>
          }
        />
        <Route
          path="/admin"
          element={
            <div className="flex flex-col justify-between h-screen">
              <AdminHeader />

              <div className="container mx-auto mb-auto">
                <Home />
              </div>
              <AdminFooter />
            </div>
          }
        />
        <Route
          path="/admin/login"
          element={
            <div className="flex flex-col justify-between h-screen">
              <AdminHeader />

              <div className="container mx-auto mb-auto">
                <Login />
              </div>
              <AdminFooter />
            </div>
          }
        />
        <Route
          path="/admin/profile"
          element={
            <div className="flex flex-col justify-between h-screen">
              <AdminHeader />

              <div className="container mx-auto mb-auto">
                <Profile />
              </div>
              <AdminFooter />
            </div>
          }
        />
        <Route
          path="/admin/settings"
          element={
            <div className="flex flex-col justify-between h-screen">
              <AdminHeader />

              <div className="container mx-auto mb-auto">
                <Settings />
              </div>
              <AdminFooter />
            </div>
          }
        />
        <Route
          path="/admin/settings/sporcular"
          element={
            <div className="flex flex-col justify-between h-screen">
              <AdminHeader />

              <div className="container mx-auto mb-auto">
                <Sporcular />
              </div>
              <AdminFooter />
            </div>
          }
        />
        <Route
          path="/admin/settings/sensei"
          element={
            <div className="flex flex-col justify-between h-screen">
              <AdminHeader />

              <div className="container mx-auto mb-auto">
                <AdminSensei />
              </div>
              <AdminFooter />
            </div>
          }
        />
        <Route
          path="/admin/settings/photos"
          element={
            <div className="flex flex-col justify-between h-screen">
              <AdminHeader />

              <div className="container mx-auto mb-auto">
                <Photos />
              </div>
              <AdminFooter />
            </div>
          }
        />
        <Route
          path="/admin/settings/katalar"
          element={
            <div className="flex flex-col justify-between h-screen">
              <AdminHeader />

              <div className="container mx-auto mb-auto">
                <Katalar />
              </div>
              <AdminFooter />
            </div>
          }
        />
        <Route
          path="/admin/settings/haberler"
          element={
            <div className="flex flex-col justify-between h-screen">
              <AdminHeader />

              <div className="container mx-auto mb-auto">
                <Haberler />
              </div>
              <AdminFooter />
            </div>
          }
        />
        <Route
          path="/admin/settings/faliyetler"
          element={
            <div className="flex flex-col justify-between h-screen">
              <AdminHeader />

              <div className="container mx-auto mb-auto">
                <Faliyetler />
              </div>
              <AdminFooter />
            </div>
          }
        />
        <Route
          path="/admin/settings/exams"
          element={
            <div className="flex flex-col justify-between h-screen">
              <AdminHeader />

              <div className="container mx-auto mb-auto">
                <AdminExams />
              </div>
              <AdminFooter />
            </div>
          }
        />
      </Rotalar>
    </div>
  );
}
