import React from "react";

const NationalAthleteCard = ({ athlete }) => {
  return (
    <div className="container flex items-center justify-between w-full my-4 bg-bayrak sm:rounded-lg">
      <div className="relative flex flex-col p-10 md:p-12 lg:p-16 gap-y-4">
        <h3 className="relative text-xl font-bold tracking-tight md:text-4xl text-write">
          {athlete.nameSurname}
        </h3>
        <div className="font-medium text-white text-md">{athlete.dan}</div>
        <p className="relative p-3 font-medium tracking-tighter text-white rounded-lg text-md bg-gradient-to-tr from-red-800 via-red-600 to-red-300">
          {athlete.turnuvalar && <ul className="flex flex-col gap-2">
              {athlete.turnuvalar &&
                athlete.turnuvalar.map((turnuva) => (
                  <li className="flex justify-between" key={0}>
                    {turnuva}
                  </li>
                ))}
            </ul>}
        </p>
      </div>
      {athlete.url !== "" && (
        <img
          className="relative hidden object-cover lg:block"
          src={athlete.url}
          alt={athlete.imageName}
        />
      )}
    </div>
  );
};

export default NationalAthleteCard;
