import React from "react";

const KyuSix = () => {
  return (
    <div className="flex justify-between px-3 bg-yellow-400 rounded w-60">
      <div></div>
      <div className="text-lg font-bold text-brand-color">6.Kyu</div>
      <div></div>
    </div>
  );
};

export default KyuSix;
