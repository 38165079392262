import React from "react";
import { BsInstagram, BsFacebook } from "react-icons/bs";
import senseiphoto from "../images/sensei.png";
import {useWindowWidth} from "@react-hook/window-size";
import { useSelector } from "react-redux";
import "../firebase"

const Sensei = () => {
  const { sensei } = useSelector((state) => state.sensei);
  const onlyWidth = useWindowWidth();
  return (
    <div className="flex items-center justify-between w-full my-4 sm:rounded-lg bg-brand-color">
      <div className="flex flex-col p-10 md:p-12 lg:p-16 gap-y-4">
        <h3 className="text-xl font-bold tracking-tight md:text-4xl text-write">
          Shihan İsmail Keskin
        </h3>
        {sensei.map((sensei) => (
          <p className="font-medium text-white md:text-lg text-md">
          {sensei.cardText}
        </p>

        ))}
        
        <div className="py-2 text-lg font-semibold text-right text-white md:text-xl">
          OSU!
        </div>
        <nav className="container flex gap-x-4">
        {onlyWidth >= 768 && (<nav className="flex gap-4 md:flex-col">
              <a
                className="text-gray-100 transition-all md:py-2 md:hover:text-gray-100 hover:text-facebook-dark md:flex md:items-center md:px-6 md:space-x-3 md:font-semibold md:rounded-sm md:w-36 lg:w-48 md:bg-gradient-to-r md:from-facebook-light md:via-indigo-700 md:to-facebook-dark md:ring-2 md:ring-blue-200 md:hover:scale-105"
                to="chart"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/ismail.keskin.3348"
              >
                <BsFacebook size={21} />
                <span className="hidden md:block">facebook</span>
              </a>
              <a
                className="text-gray-100 transition-all md:hover:text-gray-100 hover:text-insta-red md:flex md:items-center md:px-6 md:py-2 md:space-x-3 md:font-semibold md:rounded-sm md:w-36 lg:w-48 md:bg-gradient-to-r md:from-insta-via md:via-insta-purp md:to-insta-red md:ring-2 md:ring-purple-200 md:hover:scale-105"
                href="https://www.instagram.com/shihanismailkeskin/"
                to="chart"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsInstagram size={21} />
                <span className="hidden md:block">Instagram</span>
              </a>
            </nav>)}
            {onlyWidth < 768 && (<nav className="flex text-gray-200 gap-x-3">
              
              <a
                className="transition-colors hover:text-facebook-dark"
                to="chart"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/ismail.keskin.3348"
              >
                <BsFacebook size={24} />
              </a>
              
              <a
                className="transition-colors hover:text-insta-red"
                to="chart"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/shihanismailkeskin/"
              >
                <BsInstagram size={24} />
              </a>
            </nav>)}
        </nav>
      </div>

      <img className="hidden object-cover lg:block" src={senseiphoto} alt="sensei"/>
    </div>
  );
};


export default Sensei;
