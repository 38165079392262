import React from "react";

const KyuSeven = () => {
  return (
    <div className="flex justify-between px-3 bg-blue-600 rounded w-60">
      <div></div>
      <div className="text-lg font-bold text-write">7.Kyu</div>
      <div className="flex gap-x-1">
        <div className="bg-yellow-400 w-[4px] h-full"></div>
      </div>
    </div>
  );
};

export default KyuSeven;
