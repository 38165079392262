import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  faliyetler: [],
};

const faliyetler = createSlice({
  name: "faliyetler",
  initialState,
  reducers: {
    setFaliyetler: (state, action) => {
      state.faliyetler = action.payload;
      
    },
    appendFaliyet: (state, action) => {
      state.faliyetler = [ ...state.faliyetler, action.payload]
    },
  },
});

export const { setFaliyetler, appendFaliyet } = faliyetler.actions;
export default faliyetler.reducer;