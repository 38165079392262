import React from "react";
import { useSelector } from "react-redux";
import { deleteFaliyet } from "../../../firebase";
import { modal } from "../../../utils";

export default function FaliyetList() {
  
  const handleDelete = async (id) => {
    await deleteFaliyet(id);
  };
  const { faliyetler } = useSelector((state) => state.faliyetler);
  return (
    <div className="my-4">
      <div className="flex justify-center">
        <h2 className="text-2xl font-semibold">Faliyet Listesi</h2>
      </div>
      <div className="flex justify-center py-8">
        <ul className="flex flex-col w-2/5 mt-2 gap-y-2">
          {faliyetler.map((faliyet) => (
            <li
              className="flex items-center justify-between p-4 text-sm text-gray-700 rounded bg-indigo-50"
              key={faliyet.id}
            >
              {faliyet.name}
              <div className="flex gap-x-2">
                <button
                  onClick={() => handleDelete(faliyet.id)}
                  className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
                >
                  Sil
                </button>
                <button
                  onClick={() => modal("edit-faliyet-modal", faliyet)}
                  className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
                >
                  Düzenle
                </button>
              </div>
            </li>
          ))}
          {faliyetler.length === 0 && (
            <li className="flex items-center justify-center p-4 text-sm font-semibold text-red-700 rounded bg-red-50">
              Faliyet Bulunmamakta
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
