import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { MdKeyboardArrowDown } from "react-icons/md";

import YoutubeEmbed from "./ui/YoutubeEmbed";

const KataVideo = ({ kata }) => {

  const url = (kata.link).split("/")[3]
  
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <section>
      <div className="gap-2 md:gap-4">
        <button
          onClick={toggleCollapse}
          className="flex items-center text-xl font-bold text-brand-color group hover:text-title"
        >
          {kata.name}

          <button className="grid w-6 h-6 rounded-lg place-items-center">
            <span
              className={`transition-all transform ${
                isOpen ? "-rotate-180" : ""
              }`}
            >
              <MdKeyboardArrowDown
                className="group-hover:text-title"
                size={24}
              />
            </span>
          </button>
        </button>
        <Collapse isOpened={isOpen}>
          <YoutubeEmbed embedId={url} />
        </Collapse>
      </div>
    </section>
  );
};

export default KataVideo;
