import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  haberler: [],
};

const haberler = createSlice({
  name: "haberler",
  initialState,
  reducers: {
    setHaberler: (state, action) => {
      state.haberler = action.payload;
      
    },
    appendHaber: (state, action) => {
      state.haberler = [ ...state.haberler, action.payload]
    },
  },
});

export const { setHaberler, appendHaber } = haberler.actions;
export default haberler.reducer;