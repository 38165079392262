import React from "react";
import { useSelector } from "react-redux";
import { deleteSensei, deleteFile } from "../../../firebase";
import { modal } from "../../../utils";
import SenseiForm from "../forms/SenseiForm";

export default function SenseiList() {
 
  const handleDelete = async (id, bioImageName, image1Name, image2Name, image3Name) => {
    await deleteFile(bioImageName, "sensei")
    await deleteFile(image1Name, "sensei")
    await deleteFile(image2Name, "sensei")
    await deleteFile(image3Name, "sensei")
    await deleteSensei(id);
  };
  const { sensei } = useSelector((state) => state.sensei);
  return (
    <div className="my-4">
      <div className="flex justify-center">
        <h2 className="text-2xl font-semibold">Sensei Listesi</h2>
      </div>
      <div className="flex justify-center py-8">
        <ul className="flex flex-col w-2/5 mt-2 gap-y-2">
          {sensei.map((sensei) => (
            <li
              className="flex items-center justify-between p-4 text-sm text-gray-700 rounded bg-indigo-50"
              key={sensei.id}
            >
              {sensei.name}
              <div className="flex gap-x-2">
                <button
                  onClick={() => handleDelete(sensei.id, sensei.bioImageName, sensei.image1Name, sensei.image2Name, sensei.image3Name)}
                  className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
                >
                  Sil
                </button>
                <button
                  onClick={() => modal("edit-sensei-modal", sensei)}
                  className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
                >
                  Düzenle
                </button>
              </div>
            </li>
          ))}
          {sensei.length === 0 && (
            <div>
              <li className="flex items-center justify-center p-4 text-sm font-semibold text-red-700 rounded bg-red-50">
                Sensei Bilgisi Bulunmamakta
              </li>
              <SenseiForm />
            </div>
          )}
        </ul>
      </div>
    </div>
  );
}
