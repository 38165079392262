import React from "react";
import Slider from "react-slick";
import HeaderLogo from "./ui/HeaderLogo";
import { useWindowWidth } from "@react-hook/window-size";
import { NavLink } from "react-router-dom";

import { useSelector } from "react-redux";
import "../firebase";

const HeroSection = () => {
  const { photos } = useSelector((state) => state.photos);
  const onlyWidth = useWindowWidth();

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 10000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  return (
    <div className="relative h-auto my-1 before:bg-gradient-to-r before:from-brand-color before:to-transparent before:absolute before:inset-0 before:w-full before:h-full before:z-10">
      {onlyWidth >= 768 && (
        <Slider {...settings}>
          {photos.map((photo) => (
            <div>
              <img
                className="w-full h-[500px] object-cover"
                src={photo.url}
                alt={photo.name}
              />
            </div>
          ))}
        </Slider>
      )}
      <div className="relative top-0 left-0 z-20 flex items-center justify-between h-full translate-x-0 md:container md:absolute md:left-1/2 md:-translate-x-1/2 gap-x-10">
        <div className="hidden md:block">
          <NavLink to="/">
            <HeaderLogo />
          </NavLink>
          <h3 className="mt-8 text-4xl font-semibold text-center text-write text-l">
            Shihan İsmail Keskin
          </h3>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
