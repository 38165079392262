import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  katalar: [],
};

const katalar = createSlice({
  name: "katalar",
  initialState,
  reducers: {
    setKatalar: (state, action) => {
      state.katalar = action.payload;
      
    },
    appendKata: (state, action) => {
      state.katalar = [ ...state.katalar, action.payload]
    },
  },
});

export const { setKatalar, appendKata } = katalar.actions;
export default katalar.reducer;