import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FaliyetForm from "../../../components/admin/forms/FaliyetForm";
import FaliyetList from "../../../components/admin/lists/FaliyetList";
import { IoChevronBackCircle } from "react-icons/io5";
import { useSelector } from "react-redux";
import HeroLogin from "../../../components/admin/HeroLogin";
import { signin } from "../../../firebase";

export default function Faliyetler() {
  const [visible, setVisible] = useState(true);
  const [text, setText] = useState("Faliyet Listesi");
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const visibleHandle = (value) => {
    if (value === true) {
      setText("Faliyet Form");
      setVisible(false);
    } else {
      setText("Faliyet Listesi");
      setVisible(true);
    }
  };

  const onClick = () => {
    visibleHandle(visible);
  };

  const handleSubmit = async (e ,email, password) => {
    e.preventDefault();

    const user = await signin(email, password);
    if (user) {

      navigate("/admin/settings/faliyetler", {
        replace: true,
      });
    }
  };

  if (user) {
    return(
      <div className="my-5">
      <div className="flex items-center mt-5 gap-x-3">
        <Link to="/admin/settings">
          <button className="flex text-lg font-semibold text-gray-600 transition-colors hover:text-orange-600">
            <IoChevronBackCircle size={30} />
          </button>
        </Link>
        <button
          onClick={onClick}
          className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
        >
          {text}
        </button>
      </div>
      {visible === true && <FaliyetForm />}
      {visible !== true && <FaliyetList />}
    </div>
    )
  }

  return (
    <div className="my-5">
      <HeroLogin handleSubmit={handleSubmit}/>
    </div>
  );
}
