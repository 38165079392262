import React from "react";
import ExamKyu from "../components/ExamKyu";

import { useSelector } from "react-redux";
import "../firebase";

const Exams = () => {
  const { exams } = useSelector((state) => state.exams);

  return (
    <div className="container mx-4 md:px-6">
      <h1 className="pt-4 pl-4 text-2xl font-bold text-write">Sınavlar</h1>
      <div className="grid grid-cols-1 gap-4 pt-4 xl:grid-cols-2">
        {exams.map((exam) => (
          <ExamKyu exam={exam} />
        ))}
      </div>
    </div>
  );
};

export default Exams;
