import React from "react";
import { BsInstagram, BsFacebook ,BsGithub ,BsLinkedin } from "react-icons/bs";
import Menu from "./ui/Menu";
import {useWindowWidth} from "@react-hook/window-size";

const Footer = () => {
  const onlyWidth = useWindowWidth();
  
  const menus = [
    {
      title: "Keskin Dojo'yu keşfedin",
      items: [
        {
          title: "Hakkımızda",
          nav:"../about"
        },
        {
          title: "İletişim",
          nav:"../contact"
        }
      ],
    },
    
    {
      title: "Kyokushin nedir?",
      items: [
        {
          title: "Tarihçe",
          nav:"../history"
        },
        {
          title: "TKF",
          href: "http://www.karate.gov.tr/"
        },
      ],
    },
  ];

  return (
    <div className="mt-5 md:mt-10 bg-secondary-write">
      <div className="container pt-8 pb-8 mx-auto md:pt-12">
        <div className="grid grid-cols-1 px-8 md:px-0 md:grid-cols-2 gap-y-2 md:gap-y-8 lg:grid-cols-3">
          <section className="flex flex-col gap-2 md:gap-6">
            <h6 className="text-xl font-bold text-title">Sosyal Medya</h6>
            {onlyWidth >= 768 && (<nav className="flex gap-4 md:flex-col">
              <a
                className="text-gray-100 transition-all md:py-2 md:hover:text-gray-100 hover:text-facebook-dark md:flex md:items-center md:px-6 md:space-x-3 md:font-semibold md:rounded-sm md:w-36 lg:w-48 md:bg-gradient-to-r md:from-facebook-light md:via-indigo-700 md:to-facebook-dark md:ring-2 md:ring-blue-200 md:hover:scale-105"
                to="chart"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/Keskin-Dojo-1380774355585622/"
              >
                <BsFacebook size={21} />
                <span className="hidden md:block">facebook</span>
              </a>
              <a
                className="text-gray-100 transition-all md:hover:text-gray-100 hover:text-insta-red md:flex md:items-center md:px-6 md:py-2 md:space-x-3 md:font-semibold md:rounded-sm md:w-36 lg:w-48 md:bg-gradient-to-r md:from-insta-via md:via-insta-purp md:to-insta-red md:ring-2 md:ring-purple-200 md:hover:scale-105"
                href="https://www.instagram.com/keskindojo/"
                to="chart"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsInstagram size={21} />
                <span className="hidden md:block">Instagram</span>
              </a>
            </nav>)}
            {onlyWidth < 768 && (<nav className="flex text-gray-200 gap-x-3">
              
              <a
                className="transition-colors hover:text-facebook-dark"
                to="chart"
                target="_blank"
                href="https://www.facebook.com/Keskin-Dojo-1380774355585622/"
                rel="noopener noreferrer"
              >
                <BsFacebook size={24} />
              </a>
              
              <a
                className="transition-colors hover:text-insta-red"
                to="chart"
                target="_blank"
                href="https://www.instagram.com/keskindojo/"
                rel="noopener noreferrer"
              >
                <BsInstagram size={24} />
              </a>
            </nav>)}
          </section>
          {menus.map((menu, index) => (
            <Menu key={index} {...menu} />
          ))}
        </div>
        <hr className="h-[1px] bg-white my-8" />

        <div className="flex flex-col items-center md:flex-row md:justify-between">
          <section>
            <span className="text-sm font-medium tracking-tight text-gray-800">&copy; 2022 Keskin Dojo</span>
          </section>
          <section className="flex flex-col items-center gap-2 pt-2 md:gap-4 md:flex-row">
            
            <nav className="flex text-gray-200 gap-x-3">
            <a
                className="transition-colors hover:text-[#040204]"
                to="chart"
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/zekiyasin"
              >
                <BsGithub size={24} />
              </a>
              <a
                className="transition-colors hover:text-facebook-light"
                to="chart"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/zekiyasin"
              >
                <BsLinkedin size={24} />
              </a>
              
              <a
                className="transition-colors hover:text-facebook-dark"
                to="chart"
                target="_blank"
                rel="noopener noreferrer"

                href="https://www.facebook.com/zekiyasin.kocyigit/"
              >
                <BsFacebook size={24} />
              </a>
              
              <a
                className="transition-colors hover:text-insta-red"
                to="chart"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/zekiy_k/"
              >
                <BsInstagram size={24} />
              </a>
            </nav>
            <span className="text-xl font-bold tracking-tighter text-write">Created by Zeki Yasin </span>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Footer;
