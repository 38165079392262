import React, { useState } from "react";
import { updateHaber, storage, deleteFile } from '../../../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import toast from "react-hot-toast";
import { VscLoading } from "react-icons/vsc";

export default function EditHaberModal({ close, data }) {
  const [name, setName] = useState(data.name);
  const [link, setLink] = useState(data.link);
  const [idNumber, setIdNumber] = useState(data.idNumber);
  const [image, setImage] = useState(data.image);
  const [imageName, setImageName] = useState(data.imageName)
  const [url, setUrl] = useState(data.url);
  const [progress, setProgress] = useState(0);
  const [count, setCount] = useState(0);

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const countHandle = async () => {
    for (let i = 0; i <= 100; i++) {
      await sleep(30);

      setCount(i);
    }
    toast.success("resim yüklendi");
  };

  const uploadImage = (image, file) => {
    try {
      if (image == null) return;

      deleteFile(imageName,"haberler")
      setImageName(image.name)

      const imageRef = ref(storage, `${file}/${image.name}`);
      uploadBytesResumable(imageRef, image).then((snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
        console.log(progress);
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
          countHandle();
        });
      });

      setImage(null);
    } catch (error) {
      toast.error(error);
    }
  };

  const clickHandle = async (e) => {
    e.preventDefault();

    await updateHaber(data.id, {
      name,
      link,
      idNumber,
      imageName,
      url,
    });

    close();
  };
  return (
    <div className="flex flex-col gap-y-2">
      <input
        type="text"
        placeholder="Haber Adı"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
      />

      <div className="flex items-center justify-between gap-x-2">
        <input
          type="file"
          onChange={(e) => setImage(e.target.files[0])}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />
        <button
          className="flex items-center justify-between p-2 px-6 font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600 text-md"
          onClick={() => uploadImage(image, "haberler")}
          disabled={image == null}
        >
          {count >= 1 && count <= 99 && (
            <div
              className="flex items-start justify-center w-5 h-5 mr-3 bg-white rounded-full animate-spin"
              viewBox="0 0 24 24"
            >
              <VscLoading size={20} />
            </div>
          )}
          Yükle
        </button>
      </div>
      <img src={url} alt="resim" />

      <input
        type="number"
        value={idNumber}
        placeholder="Id numarası"
        onChange={(e) => setIdNumber(parseInt(e.target.value))}
        className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
      />

      <input
        type="text"
        placeholder="Link"
        value={link}
        onChange={(e) => setLink(e.target.value)}
        className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
      />

      <button
        onClick={clickHandle}
        className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
      >
        Kaydet
      </button>
    </div>
  );
}
