import React from "react";
import NationalAthleteCard from "../components/NationalAthleteCard";

import { useSelector } from "react-redux";
import "../firebase";

const NationalAthletes = () => {
  const { sporcular } = useSelector((state) => state.sporcular);

  return (
    <div className="container mx-auto md:px-6">
      <h1 className="pt-4 pl-4 text-2xl font-bold text-write">
        Milli Sporcularımız
      </h1>
      {sporcular.map(
        (sporcu) =>
          sporcu.milli === true && <NationalAthleteCard athlete={sporcu} />
      )}
    </div>
  );
};

export default NationalAthletes;
