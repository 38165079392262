import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SenseiList from "../../../components/admin/lists/SenseiList";
import { IoChevronBackCircle } from "react-icons/io5";
import { useSelector } from "react-redux";
import HeroLogin from "../../../components/admin/HeroLogin";
import { signin } from "../../../firebase";

export default function Sensei() {

  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();


  const handleSubmit = async (e ,email, password) => {
    e.preventDefault();

    const user = await signin(email, password);
    if (user) {

      navigate("/admin/settings/sensei", {
        replace: true,
      });
    }
  };

  if (user) {
    return(
      <div className="my-5">
      <div className="flex items-center mt-5 gap-x-3">
        <Link to="/admin/settings">
          <button className="flex text-lg font-semibold text-gray-600 transition-colors hover:text-orange-600">
            <IoChevronBackCircle size={30} />
          </button>
        </Link>
      </div>
      <SenseiList />
    </div>
    )
  }

  return (
    <div className="my-5">
      <HeroLogin handleSubmit={handleSubmit}/>
    </div>
  );
}
