import React from "react";
import logo from "../../images/keskin.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signout } from "../../firebase";
import { IoSettings } from "react-icons/io5";
import { RiUserFill } from "react-icons/ri";
import { FaPowerOff } from "react-icons/fa";
import { RiHomeFill } from "react-icons/ri";
import { logout as logoutHandle } from "../../store/auth";

export default function Header() {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const handleLogout = async () => {
    await signout();
    dispatch(logoutHandle());
    navigate("/admin/login", {
      replace: true,
    });
  };



  if (user) {
    return (
      <div className="py-4 bg-slate-300">
        <div className="container flex items-center justify-between mx-auto">
          <div className="flex items-center gap-x-4">
            <Link to="/">
              <img className="object-cover w-20" src={logo} alt="logo" />
            </Link>
            <Link to="/admin/">
              <h1 className="text-4xl font-bold tracking-tight">
                Keskin Dojo Admin
              </h1>
            </Link>
          </div>
          <div className="flex items-center gap-x-8">
            <Link
              className={
                splitLocation[2] === ""
                  ? "flex justify-between items-center gap-x-1 text-orange-600 text-xl font-semibold transition-colors"
                  : "flex justify-between items-center gap-x-1 text-gray-600 hover:text-orange-600 text-xl font-semibold transition-colors"
              }
              to="/admin/"
            >
              <RiHomeFill />
              <div>Anasayfa</div>
            </Link>
            <Link
              className={
                splitLocation[2] === "profile"
                  ? "flex justify-between items-center gap-x-1 text-orange-600 text-xl font-semibold transition-colors"
                  : "flex justify-between items-center gap-x-1 text-gray-600 hover:text-orange-600 text-xl font-semibold transition-colors"
              }
              to="/admin/profile"
            >
              <RiUserFill />
              <div>Profil</div>
            </Link>
            <Link
              className={
                splitLocation[2] === "settings"
                  ? "flex justify-between items-center gap-x-1 text-orange-600 text-xl font-semibold transition-colors"
                  : "flex justify-between items-center gap-x-1 text-gray-600 hover:text-orange-600 text-xl font-semibold transition-colors"
              }
              to="/admin/settings"
            >
              <IoSettings />
              <div>Ayarlar</div>
            </Link>
            <button
              onClick={handleLogout}
              className="flex items-center justify-between text-xl font-semibold text-gray-600 transition-colors gap-x-1 hover:text-orange-600"
            >
              <FaPowerOff />
              <div>Çıkış Yap</div>
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="py-4 bg-slate-300">
      <div className="container flex items-center justify-between mx-auto">
        <div className="flex items-center gap-x-4">
          <Link to="/">
            <img className="object-cover w-20" src={logo} alt="logo" />
          </Link>
          <Link to="/">
            <h1 className="text-4xl font-bold tracking-tight">
              Keskin Dojo Admin
            </h1>
          </Link>
        </div>
        <div className="flex items-center gap-x-8">
          <Link
            className={
              splitLocation[1] === "login"
                ? "flex justify-between items-center gap-x-1 text-orange-600 text-xl font-semibold transition-colors"
                : "flex justify-between items-center gap-x-1 text-gray-600 hover:text-orange-600 text-xl font-semibold transition-colors"
            }
            to="/admin/login"
          >
            <RiUserFill />
            <div>Giriş Yap</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
