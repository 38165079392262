import React from "react";

export default function ContactInfo() {
  return (
    <div className="flex flex-col gap-y-4">
      <h1 className="mt-8 text-2xl font-bold text-center text-secondary-write">
        Bizimle İletişime Geç
      </h1>
      <div className="flex justify-center gap-x-4">
        <div className="flex flex-col font-medium gap-y-8 text-secondary-write">
          <div>Email</div>
          <div>Telefon</div>
          <div>Adres</div>
        </div>
        <div className="flex flex-col font-normal gap-y-8 text-write">
          <a href="mailto:sensei_2003@hotmail.com">sensei_2003@hotmail.com</a>
          <a href="tel:+905302207216">(+90) 530 220 72 16</a>
          <a
            href="https://maps.google.com/maps?hl=tr&gl=tr&um=1&ie=UTF-8&fb=1&sa=X&ftid=0x14cadb72cec29fd3:0x2a3590034b59770c"
            to="chart"
            target="_blank"
            rel="noopener noreferrer"
          >
            Güllü Bağlar, Hürriyet Cd., 34906 Pendik/İstanbul
          </a>
        </div>
      </div>
    </div>
  );
}
