import React from "react";

const KyuFour = () => {
  return (
    <div className="flex justify-between px-3 bg-green-600 rounded w-60">
      <div></div>
      <div className="text-lg font-bold text-write">4.Kyu</div>
      <div></div>
    </div>
  );
};

export default KyuFour;