import React from "react";
import { useWindowWidth } from "@react-hook/window-size";

const ActivityList = ({ activity }) => {
    const onlyWidth = useWindowWidth();


    return(
        <div>
            <div className="grid items-center grid-cols-4 px-1 text-sm font-semibold tracking-tighter md:px-5 md:text-xl md:grid-cols-6 text-brand-color">
                <div className="pr-1">
                    {activity.name}
                </div>
                {onlyWidth > 768 && <div />}
                {onlyWidth > 768 && <div />}
                <div className="pr-1">
                    {activity.type}
                </div>
                <div className="pr-1">
                    {activity.place}
                </div>
                <div className="pr-1">
                    {activity.time}
                </div>
            </div>
            <hr className="h-[1px] bg-brand-color my-8" />
        </div>
    );
};


export default ActivityList;
