import React, { useState } from "react";
import { addKata } from "../../../firebase";

export default function KataForm() {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [puan, setPuan] = useState("");

  const submitHandle = async (e) => {
    e.preventDefault();

    await addKata({
      name,
      link,
      puan,
    });

    setName("");
    setLink("");
    setPuan("");
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center w-2/5 gap-4 p-10 my-8 bg-gray-200 rounded-md">
        <h2 className="text-2xl font-semibold">Kata Ekle</h2>

        <input
          type="text"
          placeholder="Kata Adı"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />

        <input
          type="number"
          value={puan}
          placeholder="Kata Sırası"
          onChange={(e) => setPuan(parseInt(e.target.value))}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />

        <input
          type="text"
          placeholder="Link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />

        <div>
          <button
            onClick={submitHandle}
            disabled={name === ""}
            className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
          >
            Ekle
          </button>
        </div>
      </div>
    </div>
  );
}
