import React from "react";

import { NavLink } from "react-router-dom";
const Category = ({category:{id, title, image,nav}}) => {
    return(
        <NavLink to={nav} className="flex flex-col items-center p-4 text-center transition-colors group gap-y-3 hover:bg-gray-100">
            <img className="object-cover w-12 h-12 transition-colors border border-gray-400 rounded-lg group-hover:border-write group-hover:shadow group-hover:shadow-brand-color" src={image} alt={title} />
            <span className="text-sm font-semibold tracking-tight transition-colors text-secondary-write group-hover:text-write">{title}</span>
        </NavLink>
    );
};

export default Category;