import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sporcular: [],
};

const sporcular = createSlice({
  name: "sporcular",
  initialState,
  reducers: {
    setSporcular: (state, action) => {
      state.sporcular = action.payload;
      
    },
    appendSporcu: (state, action) => {
      state.sporcular = [ ...state.sporcular, action.payload]
    },
  },
});

export const { setSporcular, appendSporcu } = sporcular.actions;
export default sporcular.reducer;
