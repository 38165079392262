import React, { useState } from "react";

const HeroLogin = ({ handleSubmit, noEmail = false }) => {
  const [email, setEmail] = useState(noEmail ? true : "");
  const [password, setPassword] = useState("");

  const handle = (e) => {
    handleSubmit(e, email, password);
  };

  return (
    
      <div className="w-full md:w-[400px] mx-auto my-auto md:rounded-lg bg-gray-100 mt-20 p-6">
        <h4 className="text-center font-semibold mb-4">Giriş yap</h4>
        <form onSubmit={handle} className="grid gap-y-3">
          {!noEmail && (
            <div>
              <div className="flex gap-x-2">
                <label
                  htmlFor="username"
                  className="flex-1 relative group block cursor-pointer"
                >
                  <input
                    id="username"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="h-14 px-4 border-2 border-gray-200 rounded w-full transition-colors group-hover:border-orange-500 focus:border-orange-500 outline-none peer text-sm pt-2"
                  />
                  <span className="absolute top-0 left-0 h-full px-4 flex items-center text-sm text-gray-500 transition-all peer-focus:h-7 peer-focus:text-orange-500 peer-focus:text-xs peer-valid:h-7 peer-valid:text-orange-500 peer-valid:text-xs">
                    E-posta adresi
                  </span>
                </label>
              </div>
            </div>
          )}

          <div className="flex gap-x-2">
            <label
              htmlFor="userPassword"
              className="flex-1 relative group block cursor-pointer"
            >
              <input
                id="userPassword"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="h-14 px-4 border-2 border-gray-200 rounded w-full transition-colors group-hover:border-orange-500 focus:border-orange-500 outline-none peer text-sm pt-2"
              />
              <span className="absolute top-0 left-0 h-full px-4 flex items-center text-sm text-gray-500 transition-all peer-focus:h-7 peer-focus:text-orange-500 peer-focus:text-xs peer-valid:h-7 peer-valid:text-orange-500 peer-valid:text-xs">
                Parola
              </span>
            </label>
          </div>

          <hr className="h-[1px] bg-gray-300 my-2" />
           
            <button
              disabled={!email || !password}
              type="submit"
              className="bg-gray-500 text-orange-500 px-4 transition-colors hover:bg-orange-500 hover:text-gray-700 h-12 flex items-center rounded-md w-full text-sm font-semibold "
            >
              <span className="mx-auto">Giriş Yap</span>
            </button>
        </form>
      </div>
    
  );
};

export default HeroLogin;
