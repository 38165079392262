import React from "react";

const KyuThree = () => {
  return (
    <div className="flex justify-between px-3 bg-green-600 rounded w-60">
      <div></div>
      <div className="text-lg font-bold text-write">3.Kyu</div>
      <div className="flex gap-x-1">
        <div className="bg-brown w-[4px] h-full"></div>
      </div>
    </div>
  );
};

export default KyuThree;
