import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useSelector } from "react-redux";
import Standing from "../components/Standing";
import "../firebase";

const Standings = () => {

  const { sporcular } = useSelector((state) => state.sporcular);
  const {photos} = useSelector((state) => state.photos)

  const url_list = photos.map(photo=>photo.url)

  const sıralama = [...sporcular]

  sıralama.sort(function(a,b){return b.puan-a.puan})

  const [isOpen, setIsOpen] = useState(true);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };


  return (
    <div className="container pt-4 mx-auto">
      <button
        onClick={toggleCollapse}
        className="flex items-center pb-5 pl-8 text-xl font-bold transition-all md:text-2xl text-write group hover:text-brand-color"
      >
        Sporcu Sıralaması
        <button className="grid w-6 h-6 pl-2 rounded-lg place-items-center">
          <span
            className={`transition-all transform ${
              isOpen ? "-rotate-180" : ""
            }`}
          >
            <MdKeyboardArrowDown
              className="group-hover:text-brand-color"
              size={24}
            />
          </span>
        </button>
      </button>
      <Collapse isOpened={isOpen}>
      <div className="flex items-center justify-between px-8">
        <div className= "text-lg font-semibold md:text-2xl text-brand-color">
          <div>Adı Soyadı</div>
          <div className="text-write">Kemer</div>
        </div>
        <div className="text-lg font-semibold text-red-500 md:text-2xl">Puan</div>
      </div>
      <hr className="h-[2px] bg-brand-color my-8" />
        
      {sıralama.map((sporcu) => sporcu.puan !== "" && (<Standing person={sporcu} />))}
      </Collapse>

      <img className={`transition-all transform object-cover w-full ${
              isOpen ? "hidden" : "block"
            }`} src={url_list[Math.floor(Math.random() * (url_list.length - 0 ) + 0)]} alt="resim" />

    </div>
  );
};

export default Standings;
