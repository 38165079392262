import React from "react";
import Card from "./ui/Card";
import card1 from "../images/card1.jpg";
import card2 from "../images/card2.jpg";
import card3 from "../images/card3.jpg";
// import cardsData from "../api/cards.json";
 
const Cards = () => {

  // const [cards, setCards] = useState([]);

  // useEffect(() => {
  //   setCards(cardsData)
  // },[])

  return (

      <div className="grid grid-cols-1 gap-4 px-4 lg:grid-cols-3 md:grid-cols-2 md:px-0">
        {/* {cards.length && cards.map(card => (
  
          <Card image={card.image}/>
        ))} */}
        <Card image={card1}/>
        <Card image={card2}/>
        <Card image={card3}/>
      </div>
    
  );
};

export default Cards;
