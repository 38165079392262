import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  exams: [],
};

const exams = createSlice({
  name: "exams",
  initialState,
  reducers: {
    setExams: (state, action) => {
      state.exams = action.payload;
      
    },
    appendExam: (state, action) => {
      state.exams = [ ...state.exams, action.payload]
    },
  },
});

export const { setExams, appendExam } = exams.actions;
export default exams.reducer;