import React from "react";
import map from "../images/map1.png"


export default function Map() {
 

  return (
    <div>
      <a
            href="https://maps.google.com/maps?hl=tr&gl=tr&um=1&ie=UTF-8&fb=1&sa=X&ftid=0x14cadb72cec29fd3:0x2a3590034b59770c"
            to="chart"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="w-full h-[300px] object-cover" src={map} alt="map" />
          </a>
      
    </div>
  );
}
