import React, { useState } from "react";
import { updateKata } from '../../../firebase';

export default function EditKataModal({ close, data }) {
  const [name, setName] = useState(data.name);
  const [link, setLink] = useState(data.link);
  const [puan, setPuan] = useState(data.puan);

  const clickHandle = async () => {
    await updateKata(data.id, {
      name,
      link,
      puan,
    });
    close();
  };

  return (
    <div className="flex flex-col gap-y-2">
      <input
        type="text"
        placeholder="Kata Adı"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
      />

      <input
        type="number"
        value={puan}
        placeholder="0"
        onChange={(e) => setPuan(parseInt(e.target.value))}
        className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
      />

      <input
        type="text"
        placeholder="Link"
        value={link}
        onChange={(e) => setLink(e.target.value)}
        className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
      />

      <button
        onClick={clickHandle}
        className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
      >
        Kaydet
      </button>
    </div>
  );
}
