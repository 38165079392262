import React from "react";

const KyuTwo = () => {
  return (
    <div className="flex justify-between px-3 rounded bg-brown w-60">
      <div></div>
      <div className="text-lg font-bold text-write">2.Kyu</div>
      <div></div>
    </div>
  );
};

export default KyuTwo;
