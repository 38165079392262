import React from "react";

const KyuOne = () => {
  return (
    <div className="flex justify-between px-3 rounded bg-brown w-60">
      <div></div>
      <div className="text-lg font-bold text-write">
        1.Kyu
        </div>
      <div className="flex gap-x-1">
        <div className="bg-black w-[4px] h-full"></div>
      </div>
    </div>
  );
};

export default KyuOne;