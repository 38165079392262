import React, { useEffect, useState } from "react";
import categoryData from "../../api/sideCategories.json";

import { slide as Menu } from "react-burger-menu";

import { NavLink } from "react-router-dom";

import { AiFillCloseCircle } from "react-icons/ai";

const Hamburger = () => {
  const HamburgerButton = ({
    closeSideBar,
    category: { id, title, image, nav },
  }) => {
    let activeClassName =
      "text-write flex p-4 transition-all group gap-y-3 bg-gray-100";

    return (
      <div>
        <NavLink
          onClick={closeSideBar}
          activeClassName="text-white bg-gray-100"
          to={nav}
          className={({ isActive }) =>
            isActive
              ? activeClassName
              : "flex p-4 transition-all group gap-y-3 hover:bg-gray-100 text-secondary-write "
          }
        >
          <span className="px-4 text-sm font-semibold tracking-tight transition-all group-hover:scale-125 group-hover:text-write">
            {title}
          </span>
        </NavLink>
        <hr className="h-[1px] bg-brand-color" />
      </div>
    );
  };

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setCategories(categoryData);
  }, []);

  const [isOpen, setOpen] = useState(false);

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const closeSideBar = () => {
    setOpen(false);
  };

  return (
    <Menu
      width={"100%"}
      isOpen={isOpen}
      onOpen={handleIsOpen}
      onClose={handleIsOpen}
    >
      <div className="w-full h-full overflow-hidden bg-white">
        <div className="w-full h-full whitespace-nowrap">
          <div className="flex items-center justify-between py-2 pb-4 mx-6 mt-2 gap-x-3">
            <button
              onClick={closeSideBar}
              className="transition-colors cursor-pointer hover:text-write "
            >
              <AiFillCloseCircle size={23} />
            </button>

            <div className="text-lg font-semibold tracking-tight text-write">
              Kategoriler
            </div>
            <div />
          </div>
          <hr className="h-[2px] bg-brand-color" />

          {categories &&
            categories.map((category, index) => (
              <HamburgerButton
                key={index}
                closeSideBar={closeSideBar}
                category={category}
              />
            ))}
        </div>
      </div>
    </Menu>
  );
};

export default Hamburger;
