import React from "react";
import HeroLogin from "../../components/admin/HeroLogin";
import { useNavigate } from "react-router-dom";
import { signin } from "../../firebase";

const Login = () => {

  const navigate = useNavigate();


  const handleSubmit = async (e ,email, password) => {
    e.preventDefault();

    const user = await signin(email, password);
    if (user) {

      navigate("/admin/profile", {
        replace: true,
      });
    }
  };

  return (
    <div>
      <HeroLogin handleSubmit={handleSubmit}/>
    </div>
  );
};

export default Login;
