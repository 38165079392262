import React, { useState } from "react";
import { useSelector } from "react-redux";
import { update } from "../../firebase";
import { setUserData } from "../../utils";

const UpdateProfile = () => {
  const { user } = useSelector((state) => state.auth);
  const [displayName, setDisplayName] = useState(user.displayName || "");
  const [avatar, setAvatar] = useState(user.photoURL || "");

  const handleSubmit = async (e) => {
    e.preventDefault();
    await update({
      displayName,
      photoURL: avatar,
    });
    setUserData();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="w-[400px] bg-gray-200 p-8 rounded-md grid gap-y-4 py-4  ">
        <h1 className="mb-2 text-xl font-bold">Profili Güncelle</h1>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Ad-Soyad
          </label>
          <div className="mt-1">
            <input
              type="text"
              placeholder="John Doe"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Avatar
          </label>
          <div className="mt-1">
            <input
              type="text"
              placeholder="photoURL"
              value={avatar}
              onChange={(e) => setAvatar(e.target.value)}
              className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
            />
          </div>
        </div>
        <button
          type="submit"
          className="flex items-center w-full h-12 px-4 text-sm font-semibold text-orange-500 transition-colors bg-gray-500 rounded-md hover:bg-orange-500 hover:text-gray-700 "
        >
          <span className="mx-auto">Güncelle</span>
        </button>
      </div>
    </form>
  );
};

export default UpdateProfile;
