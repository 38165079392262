import ReAuthModal from "./components/admin/modals/ReAuthModal";
import EditSporcuModal from "./components/admin/modals/EditSporcuModal";
import EditKataModal from "./components/admin/modals/EditKataModal";
import EditHaberModal from "./components/admin/modals/EditHaberModal";
import EditFaliyetModal from "./components/admin/modals/EditFaliyetModal";
import EditExamModal from "./components/admin/modals/EditExamModal";
import EditSenseiModal from "./components/admin/modals/EditSenseiModal";
import EditPhotoModal from "./components/admin/modals/EditPhotoModal";

const modals = [
  {
    name: "re-auth-modal",
    element: ReAuthModal,
  },
  {
    name: "edit-sporcu-modal",
    element: EditSporcuModal,
  },
  {
    name: "edit-kata-modal",
    element: EditKataModal,
  },
  {
    name: "edit-haber-modal",
    element: EditHaberModal,
  },
  {
    name: "edit-faliyet-modal",
    element: EditFaliyetModal,
  },
  {
    name: "edit-exam-modal",
    element: EditExamModal,
  },
  {
    name: "edit-sensei-modal",
    element: EditSenseiModal,
  },
  {
    name: "edit-photo-modal",
    element: EditPhotoModal,
  },
];

export default modals;
