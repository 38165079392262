import React from "react";
import SenpaiCard from "../components/SenpaiCard";

import { useSelector } from "react-redux";
import "../firebase";

const Senpai = () => {
  const { sporcular } = useSelector((state) => state.sporcular);

  return (
    <section className="container mx-auto md:px-6">
      <h1 className="pt-4 pl-4 text-2xl font-bold text-write">Senpai</h1>
      {sporcular.map(
        (sporcu) => sporcu.senpai === true && <SenpaiCard senpai={sporcu} />
      )}
    </section>
  );
};

export default Senpai;
