import React from "react";
import ActivityList from "../components/ActivityList";
import { useWindowWidth } from "@react-hook/window-size";
import { useSelector } from "react-redux";
import "../firebase";

const Activities = () => {

  const { faliyetler } = useSelector((state) => state.faliyetler);

  const onlyWidth = useWindowWidth();
  

  

  return (
    <div className="container md:px-4 pt-8 md:bg-kanku max-w-[1000px] mx-auto">
      <div className="grid grid-cols-4 px-1 text-lg font-semibold tracking-tighter text-red-500 md:px-5 md:text-xl md:grid-cols-6">
        <div className="pr-1">Faaliyet Adı</div>
        {onlyWidth > 768 && <div />}
        {onlyWidth > 768 && <div />}
        <div className="pr-1">Faaliyet Türü</div>
        <div className="pr-1">Yer</div>
        <div className="pr-1">Zaman</div>
      </div>
      <hr className="h-[2px] bg-brand-color my-8" />
      {faliyetler.map((faliyet) => (<ActivityList  activity={faliyet} />))}
    </div>
  );
};

export default Activities;
