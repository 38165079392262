import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  photos: [],
};

const photos = createSlice({
  name: "photos",
  initialState,
  reducers: {
    setPhotos: (state, action) => {
      state.photos = action.payload;
      
    },
    appendPhoto: (state, action) => {
      state.photos = [ ...state.photos, action.payload]
    },
  },
});

export const { setPhotos, appendPhoto } = photos.actions;
export default photos.reducer;