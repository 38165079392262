import React, { useState, useEffect } from "react";
import { Collapse } from "react-collapse";
import { MdKeyboardArrowDown } from "react-icons/md";

import { useWindowWidth } from "@react-hook/window-size";

import { NavLink } from "react-router-dom";

const Menu = ({ title, items }) => {
  const onlyWidth = useWindowWidth();
  const [isOpen, setIsOpen] = useState(true);

  const toggleCollapse = () => {
    if (onlyWidth <= 768) {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    if (isOpen && onlyWidth <= 768) {
      setIsOpen(false);
    }
    if (!isOpen && onlyWidth > 768) {
      setIsOpen(true);
    }
  }, [onlyWidth]);

  return (
    <section>
      <nav className="grid gap-2 md:gap-4">
        <h6
          onClick={toggleCollapse}
          className="flex items-center justify-between text-xl font-bold text-title"
        >
          {title}
          <button className="grid w-6 h-6 rounded-lg md:hidden place-items-center">
            <span
              className={`transition-all transform ${
                isOpen ? "-rotate-180" : ""
              }`}
            >
              <MdKeyboardArrowDown className="hover:text-white" size={24} />
            </span>
          </button>
        </h6>
        <Collapse isOpened={isOpen}>
          <nav>
            <ul className="grid gap-2">
              {items.map((item, key) => (
                <li
                  className="text-sm font-semibold text-gray-300 transition-colors hover:text-gray-50"
                  key={key}
                >
                  {item.nav && <NavLink to={item.nav}>{item.title}</NavLink>}

                  {item.href && (
                    <a
                      href={item.href}
                      to="chart"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </Collapse>
      </nav>
    </section>
  );
};

export default Menu;
