import React, { useState } from "react";
import { addSporcu, storage } from "../../../firebase";
import {
  ref,
  getDownloadURL,
  uploadBytesResumable
} from "firebase/storage";
import toast from "react-hot-toast";
import { VscLoading } from "react-icons/vsc";


export default function SporcuForm() {
  const [nameSurname, setNameSurname] = useState("");
  const [dan, setDan] = useState("");
  const [antrenor, setAntrenor] = useState("");
  const [hakem, setHakem] = useState("");
  const [senpai, setSenpai] = useState(false);
  const [milli, setMilli] = useState(false);
  const [senpaiText, setSenpaiText] = useState("");
  const [milliText, setMilliText] = useState("");
  const [tarih, setTarih] = useState("");
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [url, setUrl] = useState("");
  const [progress, setProgress] = useState(0);
  const [count, setCount] = useState(0);
  const [puan, setPuan] = useState("");
  const [turnuvalar, setTurnuvalar] = useState([]);

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const countHandle = async () => {
    for (let i = 0; i <= 100; i++) {
      await sleep(30);

      setCount(i);
    }
    toast.success("resim yüklendi");
  };

  const uploadImage = (image, file) => {
    try {
      if (image == null) return;

      const imageRef = ref(storage, `${file}/${image.name}`);
      uploadBytesResumable(imageRef, image).then((snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
        console.log(progress);
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          // setUrl((prev) => [...prev, url]);
          setImageName(image.name);
          setUrl(downloadURL);
          countHandle();
        });
      });

      setImage(null);
    } catch (error) {
      toast.error(error.code);
    }
  };

  const checkHandle = (value) => {
    return !value;
  };

  const textCheck = () => {
    return "";
  };

  const listHandle = (list, eleman) => {
    const newList = [...list, eleman];
    setTurnuvalar(newList);
    setMilliText("");
  };

  const delHandle = async (liste, eleman) => {
    var arr = [...liste];
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === eleman) {
        arr.splice(i, 1);
      }
    }
    setTurnuvalar(arr);
  };

  const submitHandle = async (e) => {
    e.preventDefault();

    // uploadImage(image, "sporcular");

    await addSporcu({
      nameSurname,
      dan,
      antrenor,
      hakem,
      senpai,
      milli,
      senpaiText,
      tarih,
      url,
      puan,
      imageName,
      turnuvalar,
    });

    setAntrenor("");
    setDan("");
    setHakem("");
    setMilli(false);
    setMilliText("");
    setNameSurname("");
    setSenpai(false);
    setSenpaiText("");
    setTarih("");
    setImage(null);
    setImageName("");
    setUrl("");
    setPuan("");
    setTurnuvalar([])
  };

  return (
    <div className="flex justify-center">
      <div
        // onSubmit={submitHandle}
        className="flex flex-col items-center w-2/5 gap-4 p-10 my-8 bg-gray-200 rounded-md"
      >
        <h2 className="text-2xl font-semibold">Sporcu Ekle</h2>

        <input
          type="text"
          placeholder="İsim-Soyisim"
          value={nameSurname}
          onChange={(e) => setNameSurname(e.target.value)}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />

        <div className="flex items-center justify-between gap-x-2">
          <input
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
            className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
          />
          <button
            className="flex items-center justify-between p-2 px-6 font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600 text-md"
            onClick={() => uploadImage(image, "sporcular")}
            disabled={image == null}
          >
            {count >= 1 && count <= 99 && (
              <div
                className="flex items-start justify-center w-5 h-5 mr-3 bg-white rounded-full animate-spin"
                viewBox="0 0 24 24"
              >
                <VscLoading size={20} />
              </div>
            )}
            Yükle
          </button>
        </div>

        <div className="w-full p-3 bg-gray-300 rounded-md">
          <div>Kayıt Tarihi</div>
          <input
            type="date"
            placeholder="Kayıt Tarihi"
            value={tarih}
            onChange={(e) => setTarih(e.target.value)}
            className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
          />
        </div>

        <input
          type="number"
          value={puan}
          placeholder="Puan"
          onChange={(e) => setPuan(parseInt(e.target.value))}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />
        <input
          type="text"
          placeholder="Kuşak"
          value={dan}
          onChange={(e) => setDan(e.target.value)}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />

        <input
          type="text"
          placeholder="antrenörlük"
          value={antrenor}
          onChange={(e) => setAntrenor(e.target.value)}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />
        <input
          type="text"
          placeholder="hakemlik"
          value={hakem}
          onChange={(e) => setHakem(e.target.value)}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />

        <div className="flex items-center gap-x-2">
          <input
            type="checkbox"
            checked={senpai}
            onChange={() => setSenpai(checkHandle)}
            onClick={() => setSenpaiText(textCheck)}
          />
          <span>Senpai</span>
        </div>
        <div className="flex items-center gap-x-2">
          <input
            type="checkbox"
            checked={milli}
            onChange={() => setMilli(checkHandle)}
            onClick={() => setMilliText(textCheck)}
          />
          <span>Milli Sporcu</span>
        </div>
        {senpai === true ? (
          <input
            type="text"
            placeholder="Senpai Metin"
            value={senpaiText}
            disabled={senpai !== true}
            onChange={(e) => setSenpaiText(e.target.value)}
            className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
          />
        ) : (
          <div></div>
        )}
        {milli === true ? (
          <div className="w-full">
            <ul>
              {turnuvalar &&
                turnuvalar.map((turnuva) => (
                  <li className="flex justify-between" key={0}>
                    {turnuva}
                    <button
                      className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
                      onClick={(e) => delHandle(turnuvalar, turnuva)}
                    >
                      sil
                    </button>
                  </li>
                ))}
            </ul>

            <div className="flex gap-x-2">
              <input
                type="text"
                placeholder="Milli Sporcu Metin"
                disabled={milli !== true}
                value={milliText}
                onChange={(e) => setMilliText(e.target.value)}
                className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
              />
              <button
                disabled={milliText === ""}
                className="px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
                onClick={(e) => listHandle(turnuvalar, milliText)}
              >
                Ekle
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}

        <div>
          <button
            onClick={submitHandle}
            disabled={nameSurname === ""}
            className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
          >
            Ekle
          </button>
          {/* <Button props={{text: "Ekle", onClick:{submitHandle}, disabled:{nameSurname == ''} }}/> */}
        </div>
      </div>
    </div>
  );
}
