import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sensei: [],
};

const sensei = createSlice({
  name: "sensei",
  initialState,
  reducers: {
    setSensei: (state, action) => {
      state.sensei = action.payload;
      
    },
    appendSensei: (state, action) => {
      state.sensei = [ ...state.sensei, action.payload]
    },
  },
});

export const { setSensei, appendSensei } = sensei.actions;
export default sensei.reducer;