import React from "react";

const KyuTen = () => {
  return (
    <div className="flex justify-between px-3 bg-orange-500 rounded w-60">
      <div></div>
      <div className="text-lg font-bold text-brand-color">10.Kyu</div>
      <div></div>
    </div>
  );
};

export default KyuTen;
