import React from "react";


const History = () => {
    return(
        <div className="flex items-center justify-center text-3xl font-bold">
            WE ARE WORKİNG ON IT
        </div>
    );
};

export default History;
