import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  onAuthStateChanged,
  sendEmailVerification,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import toast from "react-hot-toast";

import store from "./store";
import { login as loginHandle, logout as logoutHandle } from "./store/auth";
import { openModal } from "./store/modal";
import {
  getFirestore,
  collection,
  addDoc,
  onSnapshot,
  query,
  updateDoc,
  doc,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import { setSporcular } from "./store/sporcular";
import { setKatalar } from "./store/katalar";
import { setHaberler } from "./store/haberler";
import { setFaliyetler } from "./store/faliyetler";
import { setExams } from "./store/exams";
import { setSensei } from "./store/sensei";
import { setPhotos } from "./store/photos";
import { setUserData } from "./utils";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage();

export const signin = async (email, password) => {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    toast.success("giriş yapıldı");
    return user;
  } catch (error) {
    toast.error(error.code);
  }
};

export const signout = async () => {
  try {
    await signOut(auth);
    toast.success("çıkış yapıldı");
    return true;
  } catch (error) {
    toast.error(error.code);
  }
};

export const update = async (data) => {
  try {
    await updateProfile(auth.currentUser, data);
    toast.success("profil güncellendi");
    return true;
  } catch (error) {
    toast.error(error.code);
  }
};

export const resetPassword = async (password) => {
  try {
    await updatePassword(auth.currentUser, password);
    toast.success("parolanız güncellendi");
    return true;
  } catch (error) {
    if (error.code === "auth/requires-recent-login") {
      store.dispatch(
        openModal({
          name: "re-auth-modal",
        })
      );
    }
    toast.error(error.code);
  }
};

export const emailVerification = async () => {
  try {
    await sendEmailVerification(auth.currentUser);
    toast.success("Doğrulama maili gönderildi");
  } catch (error) {
    toast.error(error.code);
  }
};

export const reAuth = async (password) => {
  try {
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      password
    );
    const { user } = await reauthenticateWithCredential(
      auth.currentUser,
      credential
    );
    toast.success("yeniden doğrulandınız");
    return user;
  } catch (error) {
    toast.error(error.code);
  }
};

onAuthStateChanged(auth, (user) => {
  if (user) {
    setUserData();
  } else {
    store.dispatch(logoutHandle());
  }
});

export const addSporcu = async (data) => {
  try {
    const result = await addDoc(collection(db, "Sporcular"), data);
    toast.success("Sporcu Başarıyla Eklendi");
    return result;
  } catch (error) {
    toast.error(error.code);
  }
};

export const updateSporcu = async (id, data) => {
  try {
    const sporcuRef = doc(db, "Sporcular", id);
    await updateDoc(sporcuRef, data);
    toast.success("Sporcu Düzenlendi");
    return true;
  } catch (error) {
    toast.error(error.code);
  }
};

onSnapshot(
  query(collection(db, "Sporcular"), orderBy("tarih", "asc")),
  (doc) => {
    console.log(doc.docs);
    store.dispatch(
      setSporcular(
        doc.docs.reduce(
          (sporcular, sporcu) => [
            ...sporcular,
            { ...sporcu.data(), id: sporcu.id },
          ],
          []
        )
      )
    );
  }
);

export const deleteSporcu = async (id) => {
  try {
    const result = await deleteDoc(doc(db, "Sporcular", id));
    toast.success("Sporcu Silindi");
    return result;
  } catch (error) {
    toast.error(error.code);
  }
};

export const addKata = async (data) => {
  try {
    const result = await addDoc(collection(db, "Katalar"), data);
    toast.success("Kata Başarıyla Eklendi");
    return result;
  } catch (error) {
    toast.error(error.code);
  }
};

export const updateKata = async (id, data) => {
  try {
    const kataRef = doc(db, "Katalar", id);
    await updateDoc(kataRef, data);
    toast.success("Kata Düzenlendi");
    return true;
  } catch (error) {
    toast.error(error.code);
  }
};

onSnapshot(query(collection(db, "Katalar"), orderBy("puan", "desc")), (doc) => {
  console.log(doc.docs);
  store.dispatch(
    setKatalar(
      doc.docs.reduce(
        (katalar, kata) => [...katalar, { ...kata.data(), id: kata.id }],
        []
      )
    )
  );
});

export const deleteKata = async (id) => {
  try {
    const result = await deleteDoc(doc(db, "Katalar", id));
    toast.success("Kata Silindi");
    return result;
  } catch (error) {
    toast.error(error.code);
  }
};

export const addHaber = async (data) => {
  try {
    const result = await addDoc(collection(db, "Haberler"), data);
    toast.success("Haber Başarıyla Eklendi");
    return result;
  } catch (error) {
    toast.error(error.code);
  }
};

export const updateHaber = async (id, data) => {
  try {
    const kataRef = doc(db, "Haberler", id);
    await updateDoc(kataRef, data);
    toast.success("Haber Düzenlendi");
    return true;
  } catch (error) {
    toast.error(error.code);
  }
};

onSnapshot(
  query(collection(db, "Haberler"), orderBy("idNumber", "asc")),
  (doc) => {
    console.log(doc.docs);
    store.dispatch(
      setHaberler(
        doc.docs.reduce(
          (haberler, haber) => [...haberler, { ...haber.data(), id: haber.id }],
          []
        )
      )
    );
  }
);

export const deleteHaber = async (id) => {
  try {
    const result = await deleteDoc(doc(db, "Haberler", id));
    toast.success("Haber Silindi");
    return result;
  } catch (error) {
    toast.error(error.code);
  }
};

export const addFaliyet = async (data) => {
  try {
    const result = await addDoc(collection(db, "Faliyetler"), data);
    toast.success("Faliyet Başarıyla Eklendi");
    return result;
  } catch (error) {
    toast.error(error.code);
  }
};

export const updateFaliyet = async (id, data) => {
  try {
    const faliyetRef = doc(db, "Faliyetler", id);
    await updateDoc(faliyetRef, data);
    toast.success("Faliyet Düzenlendi");
    return true;
  } catch (error) {
    toast.error(error.code);
  }
};

onSnapshot(
  query(collection(db, "Faliyetler"), orderBy("time", "asc")),
  (doc) => {
    console.log(doc.docs);
    store.dispatch(
      setFaliyetler(
        doc.docs.reduce(
          (faliyetler, faliyet) => [
            ...faliyetler,
            { ...faliyet.data(), id: faliyet.id },
          ],
          []
        )
      )
    );
  }
);

export const deleteFaliyet = async (id) => {
  try {
    const result = await deleteDoc(doc(db, "Faliyetler", id));
    toast.success("Faliyet Silindi");
    return result;
  } catch (error) {
    toast.error(error.code);
  }
};

export const addExam = async (data) => {
  try {
    const result = await addDoc(collection(db, "Sınavlar"), data);
    toast.success("Sınav Başarıyla Eklendi");
    return result;
  } catch (error) {
    toast.error(error.code);
  }
};

export const updateExam = async (id, data) => {
  try {
    const examRef = doc(db, "Sınavlar", id);
    await updateDoc(examRef, data);
    toast.success("Sınav Düzenlendi");
    return true;
  } catch (error) {
    toast.error(error.code);
  }
};

onSnapshot(query(collection(db, "Sınavlar"), orderBy("que", "asc")), (doc) => {
  console.log(doc.docs);
  store.dispatch(
    setExams(
      doc.docs.reduce(
        (exams, exam) => [...exams, { ...exam.data(), id: exam.id }],
        []
      )
    )
  );
});

export const deleteExam = async (id) => {
  try {
    const result = await deleteDoc(doc(db, "Sınavlar", id));
    toast.success("Sınav Silindi");
    return result;
  } catch (error) {
    toast.error(error.code);
  }
};

export const addSensei = async (data) => {
  try {
    const result = await addDoc(collection(db, "Sensei"), data);
    toast.success("Sensei Başarıyla Eklendi");
    return result;
  } catch (error) {
    toast.error(error.code);
  }
};

export const updateSensei = async (id, data) => {
  try {
    const senseiRef = doc(db, "Sensei", id);
    await updateDoc(senseiRef, data);
    toast.success("Sensei Düzenlendi");
    return true;
  } catch (error) {
    toast.error(error.code);
  }
};

onSnapshot(query(collection(db, "Sensei")), (doc) => {
  console.log(doc.docs);
  store.dispatch(
    setSensei(
      doc.docs.reduce(
        (senseis, sensei) => [...senseis, { ...sensei.data(), id: sensei.id }],
        []
      )
    )
  );
});

export const deleteSensei = async (id) => {
  try {
    const result = await deleteDoc(doc(db, "Sensei", id));
    toast.success("Sensei Silindi");
    return result;
  } catch (error) {
    toast.error(error.code);
  }
};

export const addPhoto = async (data) => {
  try {
    const result = await addDoc(collection(db, "Photos"), data);
    toast.success("Fotoğraf Başarıyla Eklendi");
    return result;
  } catch (error) {
    toast.error(error.code);
  }
};

export const updataPhoto = async (id, data) => {
  try {
    const kataRef = doc(db, "Photos", id);
    await updateDoc(kataRef, data);
    toast.success("Fotoğraf Düzenlendi");
    return true;
  } catch (error) {
    toast.error(error.code);
  }
};

onSnapshot(query(collection(db, "Photos")), (doc) => {
  console.log(doc.docs);
  store.dispatch(
    setPhotos(
      doc.docs.reduce(
        (photos, photo) => [...photos, { ...photo.data(), id: photo.id }],
        []
      )
    )
  );
});

export const deletePhoto = async (id) => {
  try {
    const result = await deleteDoc(doc(db, "Photos", id));
    toast.success("Fotoğraf Silindi");
    return result;
  } catch (error) {
    toast.error(error.code);
  }
};

export const uploadImage = async (image, file) => {
  if (image == null) return;

  const storageRef = ref(storage, file + "/" + image.name);

  const uploadTask = uploadBytesResumable(storageRef, image);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log("Upload is " + progress + "% done");
      if (progress === 100) {
        toast.success("resim yüklendi");
      }
      switch (snapshot.state) {
        case "paused":
          console.log("Upload is paused");
          break;
        case "running":
          console.log("Upload is running");
          break;
      }
    },
    (error) => {
      toast.error(error.code);
    },
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        // console.log(downloadURL)
      });
    }
  );
};

export const deleteFile = async (file, filename) => {
  const fileRef = ref(storage, `${filename}/${file}`);

  await deleteObject(fileRef)
    .then(() => {})
    .catch((error) => {
      toast.error(error.code);
    });
};

export default app;
