import React from 'react'
import { reAuth } from '../../../firebase'
import HeroLogin from '../HeroLogin'

export default function ReAuthModal({ close }) {

    const handleSubmit = async (e,email, password) => {
        e.preventDefault()

        const result = await reAuth(password)
        close()
    }

  return (
    <div><HeroLogin handleSubmit={handleSubmit} noEmail={true}/></div>
  )
}
