import React, { useState } from "react";
import { addExam, storage } from "../../../firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import toast from "react-hot-toast";
import { VscLoading } from "react-icons/vsc";

export default function ExamForm() {
  const [name, setName] = useState("");
  const [que, setQue] = useState("");
  const [pdf, setPdf] = useState(null);
  const [url, setUrl] = useState("");
  const [pdfName, setPdfName] = useState("");
  const [progress, setProgress] = useState(0);
  const [count, setCount] = useState(0);

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const countHandle = async () => {
    for (let i = 0; i <= 100; i++) {
      await sleep(30);

      setCount(i);
    }
    toast.success("PDF yüklendi");
  };

  const uploadPdf = (pdf, file) => {
    try {
      if (pdf == null) return;

      const pdfRef = ref(storage, `${file}/${pdf.name}`);
      uploadBytesResumable(pdfRef, pdf).then((snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
        console.log(progress);
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setPdfName(pdf.name);
          setUrl(downloadURL);
          countHandle();
        });
      });

      setPdf(null);
    } catch (error) {
      toast.error(error.code);
    }
  };

  const submitHandle = async (e) => {
    e.preventDefault();

    await addExam({
      name,
      que,
      url,
      pdfName,
    });

    setName("");
    setQue("");
    setPdf(null);
    setUrl("");
    setPdfName("");
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center w-2/5 gap-4 p-10 my-8 bg-gray-200 rounded-md">
        <h2 className="text-2xl font-semibold">Sınav Ekle</h2>

        <input
          type="text"
          placeholder="Sınav Adı"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />

        <div className="flex items-center justify-between gap-x-2">
          <input
            type="file"
            onChange={(e) => setPdf(e.target.files[0])}
            className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
          />
          <button
            className="flex items-center justify-between p-2 px-6 font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600 text-md"
            onClick={() => uploadPdf(pdf, "sınavlar")}
            disabled={pdf == null}
          >
            {count >= 1 && count <= 99 && (
              <div
                className="flex items-start justify-center w-5 h-5 mr-3 bg-white rounded-full animate-spin"
                viewBox="0 0 24 24"
              >
                <VscLoading size={20} />
              </div>
            )}
            Yükle
          </button>
        </div>

        <input
          type="number"
          value={que}
          placeholder="Sınav Sırası"
          onChange={(e) => setQue(parseInt(e.target.value))}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />

        <div>
          <button
            onClick={submitHandle}
            disabled={name === ""}
            className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
          >
            Ekle
          </button>
        </div>
      </div>
    </div>
  );
}
