import React from "react";
import Slider from "react-slick";
import Title from "./ui/Title";
import { useSelector } from "react-redux";
import "../firebase";

const News = () => {
  const { haberler } = useSelector((state) => state.haberler);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 750,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="container w-auto mx-auto md:pt-8">
      <div className="hidden md:block">
        <Title childeren="Haberler" />
      </div>

      <Slider className="md:-mx-2" {...settings}>
        {haberler.length &&
          haberler.map((banner) => (
            <div key={banner.id}>
              <picture className="block px-2">
                <a
                  href={banner.link}
                  to="chart"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="object-cover w-full h-[200px] md:rounded-lg hover:scale-95 transition-all"
                    src={banner.url}
                    alt="haber"
                  />
                </a>
              </picture>
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default News;
