import React from "react";

import { NavLink } from "react-router-dom";
const Side = ({ category: { id, title, image, nav } }) => {

  let activeClassName = "text-write flex p-4 transition-all group gap-y-3 bg-gray-100";
   
  return (
    <div className="mr-2">
      <NavLink
        activeClassName="text-white bg-gray-100"
        to={nav}
        className={({ isActive }) =>
        isActive ? activeClassName :
        "flex p-4 transition-all group gap-y-3 hover:bg-gray-100 text-secondary-write "}
        
      >
        <span className="px-4 text-sm font-semibold tracking-tight transition-all group-hover:scale-125 group-hover:text-write">
          {title}
        </span>
        
      </NavLink>
      <hr className="h-[1px] bg-brand-color" />
    </div>
  );
};

export default Side;
