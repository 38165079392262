import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import HeroLogin from "../../../components/admin/HeroLogin";
import { signin } from "../../../firebase";
import logo from "../../../images/keskin.png"

export default function Settings() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleSubmit = async (e, email, password) => {
    e.preventDefault();

    const user = await signin(email, password);
    if (user) {
      navigate("/admin/settings", {
        replace: true,
      });
    }
  };

  if (user) {
    return (
      <div className="flex justify-between">
        <div className="flex flex-col gap-2 my-5">
          <button className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600">
            <Link to="/admin/settings/sensei">Sensei</Link>
          </button>
          <button className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600">
            <Link to="/admin/settings/sporcular">Sporcular</Link>
          </button>
          <button className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600">
            <Link to="/admin/settings/katalar">Katalar</Link>
          </button>
          <button className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600">
            <Link to="/admin/settings/haberler">Haberler</Link>
          </button>
          <button className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600">
            <Link to="/admin/settings/faliyetler">Faliyetler</Link>
          </button>
          <button className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600">
            <Link to="/admin/settings/exams">Sınavlar</Link>
          </button>
          <button className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600">
            <Link to="/admin/settings/photos">Fotoğraflar</Link>
          </button>
        </div>

        

        <img className="object-cover my-5 w-96" src={logo} alt="logo" />
        <div/>
        

      </div>
    );
  }

  return (
    <div className="my-5">
      <HeroLogin handleSubmit={handleSubmit} />
    </div>
  );
}
