import React from "react";
import dojokun from "../images/dojokun.jpg";

const DojoKun = () => {
  return (
    <div className="container mx-auto md:px-6">
      <h1 className="pt-4 pl-4 text-2xl font-bold text-write">Dojo Kun</h1>
      <div className="flex flex-col items-center justify-between 2xl:flex-row">
        <img className="object-cover h-full" src={dojokun} alt="dojokun"/>
        <ul className="flex flex-col p-4 text-lg font-semibold gap-y-4 md:p-0">
          <li>
            Kalplerimizi ve bedenlerimizi sağlam,yenilmez bir ruh için
            eğitecezğiz.
          </li>
          <hr className="h-[2px] bg-brand-color my-1" />
          <li>
            Savaş yolunun gerçek anlamını izleyerek duyularımızın uyanık
            olmasını sağlayacağız.
          </li>
          <hr className="h-[2px] bg-brand-color my-1" />
          <li>Gerçek güçle özgüvenimizi geliştirmek için çaba göstereceğiz.</li>
          <hr className="h-[2px] bg-brand-color my-1" />
          <li>
            Nezaket kurallarına uyarak üstlerimize saygı gösterip şiddetten
            kaçınacağız.
          </li>
          <hr className="h-[2px] bg-brand-color my-1" />
          <li>
            Geleneklerimizi takip ederek davranışlarımızda mütavazi olacağız.
          </li>
          <hr className="h-[2px] bg-brand-color my-1" />
          <li>
            Bilgelik ve güç için gayret gösterip başka arzular aramayacağız.
          </li>
          <hr className="h-[2px] bg-brand-color my-1" />
          <li>
            Hayatımız boyunca, Karate disipliniyle Kyokushin yolunu takip
            edeceğiz.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DojoKun;
